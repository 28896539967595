code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a:link {
  color: #ffc0cb;
  text-decoration: none;
}
a:visited {
  color: #ffc0cb;
}
a:hover {
  color: #ff99aa;
  text-decoration: none;
}
a:active {
  color: #212529;
}

.nav-moderno a {
  background: inherit;
  color: inherit;
}
.nav-moderno a span {
  padding-bottom: 0.5em;
}
.nav-moderno a:active {
  outline: none;
}
.nav-moderno a.active {
  outline: none;
}
.nav-moderno a.active span {
  border-bottom: 2px solid currentColor;
}
